import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function MsgSent({ data }) {
  return (
    <Layout>
      <SEO title="Your Message was sent" />
      <h1>Your message has been sent</h1>
      <p>
        Thanks for reaching out to us. We will get back to you as soon as we can. 
      </p>
      <p>You can return to the homepage by <Link to="/">clicking here</Link>.</p>
    </Layout>
  )
}

